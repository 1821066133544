.container: {
  width: '100%';
  paddingTop: 18;
  paddingBottom: 24;
},
.hasSubtitle: {
  paddingBottom: 8;
},
.title: {
  fontSize: 22;
  textAlign: 'center';
  color: '#FFF';
}
