.App-header {
  padding-top: 10px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: 10px;
}
.App-header > .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.App-header img {
  flex: 0;
}
.App-header ul {
  flex: 1;
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: flex-end;
}
.App-header ul li {
  padding: 2px 20px;
  font-size: 14px;
  font-weight: 400;
}
.App-header ul li a {
  text-decoration: none !important;
  color: #777777;
}

.App-header ul li a.selected {
  color: black;
  font-weight: bold;
}
